import React from "react"
import Layout from "../components/Layout"

const BusinessAssociateAgreement = () => {
  return (
    <Layout>
      <div className="public_page">
        <p>
          <strong>BUSINESS ASSOCIATE AGREEMENT</strong>
        </p>
        <p>
          BY SUBSCRIBING TO OUR SERVICES AND CLICKING ON THE &ldquo;
          <strong>I ACCEPT</strong>&rdquo; button that is part of the Sign Up
          form, you agree to be bound to the terms and conditions set forth in
          this Business Association Agreement (the &ldquo;Agreement&rdquo;)
          governing the use and disclosure of Protected Health Information (PHI)
          provided by you as a HIPAA Covered Entity (the "Covered Entity",
          &ldquo;you&rdquo;, &ldquo;your&rdquo;) to ALIGNERBASE LLC, DBA
          AlignerBase at 1007 N Orange St Ste 1357, 4<sup>th</sup> Floor,
          Wilmington, DE 19801 as a Business Associate of a HIPAA Covered Entity
          (the "Business Associate", &ldquo;we&rdquo;, &ldquo;us&rdquo;,
          &ldquo;our&rdquo;). This Agreement is effective immediately and from
          time to time Covered Entity and Business Associate may be referred to
          herein collectively as the "Parties" or individually as "Party".
        </p>
        <p>
          <strong>RECITALS</strong>
        </p>
        <p>
          WHEREAS, the Business Associate provides certain services to Covered
          Entity as stated in the{" "}
          <a href="https://alignerbase.com/terms-of-service">
            Terms of Service
          </a>{" "}
          documents displayed on the website of the Business Associate. In
          connection with Business Associate's services, Business Associate may
          create, receive, maintain, or transmit Protected Health Information
          (PHI) from, to, or on behalf of Covered Entity, information that is
          subject to protection under the Federal Health Insurance Portability
          and Accountability Act of 1996, (the "HIPAA Statute"), the Health
          Information Technology for Economic and Clinical Health Act and
          related regulations promulgated by the Secretary ("HIPAA
          Regulations").
        </p>
        <p>
          WHEREAS, Business Associate qualifies as a "business associate" (as
          defined by the HIPAA Regulations) of its clients, which means that
          Business Associate has certain responsibilities with respect to the
          Protected Health Information of its clients; and
        </p>
        <p>
          WHEREAS, in light of the foregoing and the requirements of the HIPAA
          Act and HIPAA Regulations, Business Associate and Covered Entity agree
          to be bound by the terms and conditions of this Agreement.
        </p>
        <p>
          NOW, THEREFORE, in consideration of the Parties&rsquo; continuing
          obligations under the HIPAA and the regulations promulgated thereunder
          regarding privacy and security of PHI, and for good and valuable
          consideration, the receipt and sufficiency of which are hereby
          acknowledged, the parties agree as follows:
        </p>
        <ol style={{ listStyle: "auto" }}>
          <li>
            <strong> DEFINITIONS:</strong>
            <p>
              "
              <strong>
                <em>Protected Health Information</em>
              </strong>
              " shall have the same meaning as the term "protected health
              information" in 45 CFR &sect; 160.103, limited to the information
              created, received, maintained or transmitted by Business Associate
              from or on behalf of Covered Entity.
            </p>
            <p>
              "
              <strong>
                <em>Electronic Protected Health Information</em>
              </strong>
              " shall have the same meaning as the term "electronic protected
              health information" in 45 CFR &sect; 160.103, limited to the
              information that Business Associate creates, receives, maintains,
              or transmits from or on behalf of Covered Entity.
            </p>
            <p>
              "
              <strong>
                <em>Electronic Health Record</em>
              </strong>
              " shall have the same meaning as the term "electronic health
              record" in the HITECH Act, Section 13400(5)
            </p>
            <p>
              "
              <strong>
                <em>Individual</em>
              </strong>
              " shall have the same meaning as the term "individual" in 45 CFR
              &sect; 160.103 and shall include a person who qualifies as a
              personal representative in accordance with 45 CFR &sect; I
              64.502(g).
            </p>
            <p>
              "
              <strong>
                <em>Privacy Rule</em>
              </strong>
              " shall mean the Standards for Privacy of Individually
              Identifiable Health Information at 45 CFR Part 160 and Part 164.
            </p>
            <p>
              "
              <strong>
                <em>Required by Law</em>
              </strong>
              " shall have the same meaning as the term "required by law" in 45
              CFR &sect; 164.103.
            </p>
            <p>
              "
              <strong>
                <em>Secretary</em>
              </strong>
              " shall mean the Secretary of the Department of Health and Human
              Services or his designee.
            </p>
            <p>
              "
              <strong>
                <em>Security Rule</em>
              </strong>
              " shall mean the Security Standards at 45 CFR Part 160 and Part
              164.
            </p>
            <p>
              &nbsp;&ldquo;
              <strong>
                <em>Security Incident</em>
              </strong>
              &rdquo; means the attempted or successful unauthorized access,
              use, disclosure, modification, or destruction of information or
              interference with system operations in an information system.
            </p>
            <p>
              "
              <strong>
                <em>Subcontractor</em>
              </strong>
              " shall have the same meaning as the term "subcontractor" in 45
              CFR &sect; 160.103.
            </p>
            <p>
              <em>
                "<strong>Unsecured Protected Health Information</strong>"
              </em>{" "}
              shall have the same meaning as the term "unsecured protected
              health information" in 45 CFR &sect; 164.402.
            </p>
            <p>
              "
              <strong>
                <em>HIPAA</em>
              </strong>
              " collectively refers to the HIPAA Statute, the HITECH Act, and
              the HIPAA Regulations, as such may be amended from time to time.
            </p>
            <p>
              &nbsp;&ldquo;
              <strong>
                <em>HITECH Act</em>
              </strong>
              &rdquo; means the Health Information Technology for Economic and
              Clinical Health Act, enacted as part of the American Recovery and
              Reinvestment Act of 2009, Public Law 111-005.
            </p>
            <p>
              &ldquo;
              <strong>
                <em>HHS</em>
              </strong>
              &rdquo; means the U.S. Department of Health and Human Services
            </p>
            <p>
              "
              <strong>
                <em>Privacy Rule</em>
              </strong>
              " shall mean the Standards for Privacy of Individually
              Identifiable Health Information at 45 CFR part 160 and part 164.
            </p>
            <p>
              "
              <strong>
                <em>Security Rule</em>
              </strong>
              " shall mean the Security Standards at 45 CFR part 16 and part
              164.
            </p>
            <p>
              &ldquo;
              <strong>
                <em>Service Agreement</em>
              </strong>
              &rdquo; for purposes of this Agreement, "Service Agreement" shall
              refer to Terms of Service documents displayed here{" "}
              <a href="http://www.alignerbase.com/terms-of-service">
                www.alignerbase.com/terms-of-service
              </a>
              &nbsp;
            </p>
            <p>
              &ldquo;
              <strong>
                <em>Privacy Policy</em>
              </strong>
              &rdquo;: for purposes of this Agreement, "Service Agreement" shall
              refer to Terms of Service documents displayed here{" "}
              <a href="http://www.alignerbase.com/privacy-policy">
                www.alignerbase.com/privacy-policy
              </a>
            </p>
            <p>
              "
              <strong>
                <em>Breach</em>
              </strong>
              " shall have the same meaning as the term "breach" in 45 CFR
              &sect; 164.402.
            </p>
          </li>

          <li>
            <strong> ACKNOWLEDGMENT:</strong>
            <p>
              Business Associate acknowledges and agrees that all Protected
              Health Information that is created or received from the
              Contracting Entity and disclosed or made available in any form,
              including paper record, oral communication, audio recording, and
              electronic media by Contracting Entity or its operating units to
              Business Associate or is created or received by Business Associate
              on Contracting Entity&rsquo;s behalf shall be subject to this
              Agreement.
            </p>
          </li>
          <li>
            <strong>
              PERMITTED USES AND DISCLOSURES OF PHI BY BUSINESS ASSOCIATE:
            </strong>
          </li>
          <ol style={{ listStyle: "lower-alpha" }}>
            <li>
              <u>Service Agreement.</u> Except as otherwise limited in this
              Agreement, Business Associate may only use or disclose PHI to
              perform functions, activities, or services for, or on behalf of,
              Covered Entity as specified in the Service Agreement and Privacy
              Policy or as required by applicable law, rule or regulation, or by
              accrediting or credentialing organization to whom Contracting
              Entity is required to disclose such information, provided that
              such use or disclosure would not violate HIPAA if done by Covered
              Entity or the minimum necessary policies and procedures of the
              Covered Entity. All such uses and disclosures shall be consistent
              with the minimum necessary requirements of HIPAA.
            </li>
            <li>
              <u>Use for Administration of Business Associate. </u>Except as
              otherwise limited in this Agreement, Business Associate may use
              Protected Health Information for the proper management and
              administration of Business Associate or to carry out the legal
              responsibilities of Business Associate.
            </li>
            <li>
              <u>Disclosure for Administration of Business Associate. </u>Except
              as otherwise limited in this Agreement, Business Associate may
              disclose Protected Health Information for the proper management
              and administration of the Business Associate, provided that (i)
              disclosures are Required By Law, or (ii) Business Associate
              obtains reasonable assurances from the person to whom the
              information is disclosed that the Protected Health Information
              will remain confidential and he used or further disclosed only as
              Required By Law or for the purpose for which it was disclosed to
              the person, and the person notifies the Business Associate of any
              instances of which it is aware in which the confidentiality of the
              information has been breached.
            </li>
          </ol>
          <li>
            <strong> PERMISSIBLE REQUESTS BY COVERED ENTITY</strong>
            <u>:</u>
            <p>
              Except as set forth in Section 2 of this Agreement, Covered Entity
              shall not request Business Associate to use or disclose Protected
              Health Information in any manner that would not be permissible
              under the Privacy Rule if done by Covered Entity.
            </p>
          </li>

          <li>
            <strong> SAFEGUARDS AGAINST MISUSE OF PHI: </strong>
            <p>
              Business Associate will use appropriate safeguards to prevent the
              use or disclosure of PHI other than as provided by the Agreement
              or this Agreement and Business Associate agrees to implement
              administrative, physical, and technical safeguards that reasonably
              and appropriately protect the confidentiality, integrity and
              availability of the Electronic PHI that it creates, receives,
              maintains or transmits on behalf of Covered Entity. Business
              Associate agrees to take reasonable steps; including providing
              adequate training to its employees to ensure compliance with this
              Agreement and to ensure that the actions or omissions of its
              employees or agents do not cause Business Associate to breach the
              terms of this Agreement.
            </p>
          </li>

          <li>
            <strong>
              {" "}
              REPORTING DISCLOSURES OF PHI AND SECURITY INCIDENTS:
            </strong>
            <p>
              Business Associate will report to Covered Entity in writing any
              use or disclosure of PHI not provided for by this Agreement of
              which it becomes aware and Business Associate agrees to report to
              Covered Entity any Security Incident affecting Electronic PHI of
              Covered Entity of which it becomes aware. Business Associate
              agrees to report any such event within five business days of
              becoming aware of the event.
            </p>
          </li>

          <li>
            <strong> REPORTING BREACHES OF UNSECURED PHI: </strong>
            <p>
              Business Associate will notify Covered Entity in writing promptly
              upon the discovery of any Breach of Unsecured PHI in accordance
              with the requirements set forth in 45 CFR &sect;164.410, but in no
              case later than 30 calendar days after discovery of a Breach.
            </p>
          </li>

          <li>
            <strong> MITIGATION OF DISCLOSURES OF PHI</strong>:
            <p>
              Business Associate will take reasonable measures to mitigate, to
              the extent practicable, any harmful effect that is known to
              Business Associate of any use or disclosure of PHI by Business
              Associate or its agents or subcontractors in violation of the
              requirements of this Agreement.
            </p>
          </li>

          <li>
            <strong> AGREEMENTS WITH AGENTS OR SUBCONTRACTORS: </strong>
            <p>
              Business Associate will ensure that any of its agent or
              subcontractors that have access to, or to which Business Associate
              provides PHI, agree to the restrictions and conditions concerning
              uses and disclosures of PHI contained in this Agreement and agree
              to implement reasonable and appropriate safeguards to protect any
              Electronic PHI that it creates, receives, maintains, or transmits
              on behalf of Business Associate or, through the Business
              Associate.
            </p>
          </li>

          <li>
            <strong> AGENTS/SUBCONTRACTORS: </strong>
            <p>
              Business Associate agrees to ensure that any agent and/or
              subcontractor that creates, receives, maintains or transmits
              Protected Health Information on behalf of Business Associate
              agrees in writing to restrictions and conditions at least as
              stringent as those that apply to Business Associate pursuant to
              this Agreement with respect to such information. Business
              Associate agrees that, in the event that Business Associate
              becomes aware of a pattern of activity or practice of an agent
              and/or subcontractor that constitutes a material breach or
              violation by the agent and/or subcontractor of any such
              restrictions or conditions, Business Associate shall take
              reasonable steps to cure the breach or end the violation, as
              applicable, and if such steps are unsuccessful, to terminate the
              contract or arrangement with such agent and/or subcontractor.
            </p>
          </li>

          <li>
            <strong> ACCESS TO PHI BY INDIVIDUALS:</strong>
            <ol style={{ listStyle: "lower-alpha" }}>
              <li>
                Upon request, Business Associate agrees to furnish Covered
                Entity with copies of the PHI maintained by Business Associate
                in a Designated Record Set in the time and manner designated by
                Covered Entity to enable Covered Entity to respond to an
                Individual&rsquo;s request for access to PHI under 45 CFR
                &sect;164.524.
              </li>
              <li>
                In the event any Individual or personal representative requests
                access to the Individual&rsquo;s PHI directly from Business
                Associate, Business Associate within ten business days, will
                forward that request to Covered Entity. Any disclosure of, or
                decision not to disclose, the PHI requested by an Individual or
                a personal representative and compliance with the requirements
                applicable to an Individual&rsquo;s right to obtain access to
                PHI shall be the sole responsibility of Covered Entity.
              </li>
            </ol>
          </li>

          <li>
            <strong> AMENDMENT OF PHI:</strong>
          </li>
          <ol style={{ listStyle: "lower-alpha" }}>
            <li>
              Upon request and instruction from Covered Entity, Business
              Associate will amend PHI or a record about an Individual in a
              Designated Record Set that is maintained by, or otherwise within
              the possession of, Business Associate as directed by Covered
              Entity in accordance with procedures established by 45 CFR
              &sect;164.526. Any request by Covered Entity to amend such
              information will be completed by Business Associate within 15
              business days of Covered Entity&rsquo;s request.
            </li>
            <li>
              In the event that any Individual requests that Business Associate
              amend such Individual&rsquo;s PHI or record in a Designated Record
              Set, Business Associate within ten business days will forward this
              request to Covered Entity. Any amendment of, or decision not to
              amend, the PHI or record as requested by an Individual and
              compliance with the requirements applicable to an
              Individual&rsquo;s right to request an amendment of PHI will be
              the sole responsibility of Covered Entity.
            </li>
          </ol>
          <li>
            <strong> ACCOUNTING OF DISCLOSURES:</strong>
          </li>
          <ol style={{ listStyle: "lower-alpha" }}>
            <li>
              Business Associate will document any disclosures of PHI made by it
              to account for such disclosures as required by 45 CFR
              &sect;164.528(a). Business Associate also will make available
              information related to such disclosures as would be required for
              Covered Entity to respond to a request for an accounting of
              disclosures in accordance with 45 CFR &sect;164.528. At a minimum,
              Business Associate will furnish Covered Entity the following with
              respect to any covered disclosures by Business Associate: (i) the
              date of disclosure of PHI; (ii) the name of the entity or person
              who received PHI, and, if known, the address of such entity or
              person; (iii) a brief description of the PHI disclosed; and (iv) a
              brief statement of the purpose of the disclosure which includes
              the basis for such disclosure.
            </li>
            <li>
              Business Associate will furnish to Covered Entity information
              collected in accordance with this Section 10, within ten business
              days after written request by Covered Entity, to permit Covered
              Entity to make an accounting of disclosures as required by 45 CFR
              &sect;164.528, or in the event that Covered Entity elects to
              provide an Individual with a list of its business associates,
              Business Associate will provide an accounting of its disclosures
              of PHI upon request of the Individual, if and to the extent that
              such accounting is required under the HITECH Act or under HHS
              regulations adopted in connection with the HITECH Act.
            </li>
            <li>
              In the event an Individual delivers the initial request for an
              accounting directly to Business Associate, Business Associate will
              within ten business days forward such request to Covered Entity.
            </li>
          </ol>
          <li>
            <strong> AVAILABILITY OF BOOKS AND RECORDS:</strong>
            <p>
              &nbsp;Business Associate will make available its internal
              practices, books, agreements, records, and policies and procedures
              relating to the use and disclosure of PHI, upon request, to the
              Secretary of HHS for purposes of determining Covered
              Entity&rsquo;s and Business Associate&rsquo;s compliance with
              HIPAA, and this Agreement.
            </p>
          </li>

          <li>
            <strong> RESPONSIBILITIES OF COVERED ENTITY:</strong>
            <p>
              With regard to the use and/or disclosure of Protected Health
              Information by Business Associate, Covered Entity agrees to:
            </p>
            <ol style={{ listStyle: "lower-alpha" }}>
              <li>
                Notify Business Associate of any limitation(s) in its notice of
                privacy practices in accordance with 45 CFR &sect;164.520, to
                the extent that such limitation may affect Business
                Associate&rsquo;s use or disclosure of PHI.
              </li>
              <li>
                Notify Business Associate of any changes in, or revocation of,
                permission by an Individual to use or disclose Protected Health
                Information, to the extent that such changes may affect Business
                Associate&rsquo;s use or disclosure of PHI.
              </li>
              <li>
                Notify Business Associate of any restriction to the use or
                disclosure of PHI that Covered Entity has agreed to in
                accordance with 45 CFR &sect;164.522, to the extent that such
                restriction may affect Business Associate&rsquo;s use or
                disclosure of PHI.
              </li>
              <li>
                Except for data aggregation or management and administrative
                activities of Business Associate, Covered Entity shall not
                request Business Associate to use or disclose PHI in any manner
                that would not be permissible under HIPAA if done by Covered
                Entity.
              </li>
            </ol>
          </li>

          <li>
            <strong> TERMINATION&nbsp;</strong>
            <ol style={{ listStyle: "lower-alpha" }}>
              <li>
                <u>Termination by Covered Entity</u>: Upon Covered Entity's
                knowledge of a material breach by Business Associate of this
                Agreement, Covered Entity may either:
                <ol style={{ listStyle: "lower-roman" }}>
                  <li>
                    Provide an opportunity for Business Associate to cure the
                    breach or end the violation. If Business Associate does not
                    cure the breach or end the violation within the time
                    specified by Covered Entity, Covered Entity may terminate:
                    this Agreement or applicable Service Agreement that involve
                    the use or disclosure of Protected Health Information; .
                  </li>
                  <li>
                    If Business Associate has breached a material term of this
                    Agreement and cure is not possible, the Covered Entity may
                    immediately terminate this Agreement or applicable Service
                    Agreement that involve the use or disclosure of Protected
                    Health Information; . But, in any event Business Associate
                    will not liable for any material breach by Covered Entity.
                  </li>
                </ol>
              </li>

              <li>
                <u>Effect of Termination/</u>
                <u>Obligations of Business Associate upon Termination</u>: Upon
                termination of this agreement or the applicable Service
                Agreement, for any reason, Business Associate shall return or
                destroy all Protected Health Information received from Covered
                Entity, or created or received by Business Associate on behalf
                of Covered Entity. This provision shall also apply to PHI that
                is in the possession of subcontractors or agents of Business
                Associate. Business Associate shall retain no copies of the PHI.
                In the event that Business Associate determines that returning
                or destroying the Protected Health Information is infeasible,
                Business Associate shall provide to Covered Entity notification
                of the conditions that make return or destruction infeasible and
                the Business Associate shall extend the protections of this
                Agreement to such PHI and limit further uses and disclosures of
                such PHI to those purposes that make the return or destruction
                infeasible, for so long as Business Associate maintains such
                PHI. In addition, with respect to Electronic PHI, Business
                Associate shall continue to use appropriate safeguards and
                comply with Subpart C of 45 CFR Part 164, to prevent the use or
                disclosure of the PHI, for as long as Business Associate retains
                the Electronic Protected Health Information.
              </li>
            </ol>
          </li>

          <li>
            <strong> MISCELLANEOUS PROVISIONS: </strong>
            <ol style={{ listStyle: "lower-alpha" }}>
              <li>
                <u>Indemnification</u>: Both parties shall indemnify and hold
                harmless each other and their employees, trustees, professional
                staff representatives and agents from and against all claims,
                liabilities, judgments, fines, assessments, penalties, awards or
                other expenses, of any kind or nature whatsoever, including,
                without limitation, attorney&rsquo;s fees, expert witness fees,
                and costs of investigation, litigation or dispute resolution,
                relating to or arising out of any breach or alleged breach of
                this Agreement.
              </li>
              <li>
                <u>Independent Entities:</u> None of the provisions of this
                Agreement is intended to create, nor shall any be construed to
                create, any relationship between the Parties other than that of
                independent entities contracting with each other solely to
                effectuate the provisions of the Agreement. The Parties to this
                Agreement do not intend to create any rights in any third
                parties.
              </li>
              <li>
                <u>No HIPAA Agency Relationship</u>: It is not intended that an
                agency relationship (as defined under the Federal common law of
                agency) be established hereby expressly or by implication
                between Covered Entity and Business Associate for purposes of
                liability under HIPAA, HIPAA Regulations, or the HITECH Act. No
                terms or conditions contained in this BA Agreement shall be
                construed to make or render Business Associate an agent of
                Covered Entity.
              </li>
              <li>
                <u>Regulatory References</u>: A reference in this Agreement to a
                section in HIPAA means the section as in effect or as amended or
                modified from time to time, including any corresponding
                provisions of subsequent superseding laws or regulations.
              </li>
              <li>
                <u>Amendment:</u> The Business Associates may take such action
                as is necessary to amend the Service Agreement, Privacy Policy
                or this Agreement from time to time as is necessary for Covered
                Entity to comply with the requirements of HIPAA without the
                prior written consent of the Covered Entity.&nbsp;
              </li>
              <li>
                <u>Survival:</u> In the event that any provision of this
                Agreement is held by a court of competent jurisdiction to be
                invalid or unenforceable, the remainder of the provisions of
                this Agreement will remain in full force and effect. Also, the
                respective rights and obligations of Business Associate under
                this Agreement shall survive the termination of the Service
                Agreement or this Agreement.
              </li>
              <li>
                <u>Interpretation:</u> Any ambiguity in this Agreement shall be
                resolved to permit Covered Entity to comply with HIPAA.
              </li>
              <li>
                <u>Third Party Beneficiaries</u>: Covered Entity's subsidiaries
                and affiliates shall be considered third party beneficiaries of
                this Agreement and the agreements contained therein.
              </li>
              <li>
                <u>Governing Law and Jurisdiction</u>: This Agreement shall be
                governed by, and construed in accordance with, the laws of the
                State of Delaware, exclusive of conflict of law rules. Each
                party to this Agreement hereby agrees and consents that any
                legal action or proceeding with respect to this Agreement shall
                only be brought in the state and federal courts located in New
                Castle County, Delaware.
              </li>
              <li>
                <u>Entire Agreement:</u> This Agreement constitutes the entire
                agreement between the parties with respect to the subject matter
                contained herein and this Agreement supersedes and replaces any
                former business associate agreement or addendum entered into by
                the parties related to this subject matter.&nbsp;In the event of
                a conflict between the terms of this Agreement and the terms of
                the Service Agreement, the terms of this Agreement shall
                prevail.
              </li>
              <li>
                <u>Compliance with HHS Requirements:</u> In the event a Party
                believes in good faith that any provision of this Agreement
                fails to comply with the then-current Requirements issued by
                HHS, such Party shall notify the other Party in writing, for a
                period of up to thirty days, the Parties shall address in good
                faith such concern and amend the terms of this Agreement, if
                necessary to bring it into compliance. If, after such thirty-day
                period, the Agreement fails to comply with the Requirements,
                either Party has the right to terminate upon written notice to
                the other Party.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </Layout>
  )
}

export default BusinessAssociateAgreement
